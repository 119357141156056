export default {
  name: "ListTLoanAccount",
  components: {},
  computed: {
    officeCodeSession() {
      let getSession = JSON.parse(
        this.decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
      ).officeCode;
      this.property.filterDto.officeCode;
      return getSession;
    },
  },
  data() {
    return {
      property: {
        animation: {
          checkingLoanSp3: {
            isLoading: false,
          },
          updateAo: {
            isLoading: false,
          }
        },
        modal: {
          showModalCheckingLoanSp3: false,
          showModalResultLoanSp3: false,
          showModalUpdateAo: false,
        },
        listElement: {
          loanAccount: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            downloading: false,
            message: "",
          },
          loanSp3: {
            rows: 0,
            perPage: 5,
            currentPage: 1,
            downloading: false,
            message: "",
          },
        },
        filterDto: {
          loanAccountNumber: "",
          officeCode: "",
          cifIdName: "",
          dateFrom: "",
          dateTo: "",
          cityName: "",
          referalOfficeId: "",
          orderByBalanceEnd: false,
          statusId: "",
        },
      },
      dataForm: {
        loanSp3Checking: {
          cifIdName: "",
          cifNumber: "",
          cabang: "",
        },
        updateAo: {
          accountOfficerIdHandleId: "",
          accoundOfficerPromoId: "",
          loanAccountId: "",
        },
      },
      session: {
        loanSp3Checking: {
          cifIdName: "",
          cifNumber: "",
        },
      },
      table: {
        totalRekening: 0,
        totalNominatif: 0,
        data: {
          loanAccount: [],
          loanSp3: [],
        },
      },
      options: {
        cabang: [],
        office: [],
        officeReferal: [],
        statusAccount: [],
        accountOfficer: [],
      },
    };
  },
  methods: {
    globalDirectiveCheckLoanInstallmentHistory(props) {
      sessionStorage.setItem(
        "LOAN_ACCOUNT_NUMBER",
        props.row.loanAccountNumber
      );
      this.$router.push("/report/history-angsuran/detail");
    },
    async changePaginationLoanAccount(event) {
      this.table.data.loanAccount = [];
      this.property.listElement.loanAccount.downloading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "transaction-loan/simple-list",
          payload: {
            loanAccountNumber: this.property.filterDto.loanAccountNumber,
            dateFrom: this.property.filterDto.dateFrom,
            officeCode: this.property.filterDto.officeCode,
            dateTo: this.property.filterDto.dateTo,
            cifIdName: this.property.filterDto.cifIdName,
            cityName: this.property.filterDto.cityName,
            statusId: this.property.filterDto.statusId,
            referalOfficeId: this.property.filterDto.referalOfficeId,
            orderByBalanceEnd: this.property.filterDto.orderByBalanceEnd,
            isSubContract: false,
            page: event - 1,
            size: this.property.listElement.loanAccount.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.loanAccount = resp.data.data.pageResponse.content;
            this.table.totalRekening = resp.data.data.totalAccount;
            this.table.totalNominatif = resp.data.data.totalNominative;
            this.property.listElement.loanAccount.rows =
              resp.data.data.pageResponse.totalElements;
          } else {
            this.failedGetLoanAccount(resp);
            this.table.totalRekening = 0;
            this.table.totalNominatif = 0;
          }
        }, 500);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetLoanAccount(error);
        }, 500);
      } finally {
        setTimeout(() => {
          this.property.listElement.loanAccount.downloading = false;
        }, 500);
      }
    },
    async rejectAuthorizeLoanAccount(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
      }
      this.$buefy.dialog.prompt({
        message: `Reject Authorisasi Kontrak <b>${props.row.loanAccountNumber}</b> ?`,
        inputAttrs: {
          placeholder: "Deskripsi",
        },
        type: "is-link",
        confirmText: "Konfirmasi",
        cancelText: "Batal",
        closeOnConfirm: false,
        trapFocus: true,
        onConfirm: async (value, { close }) => {
          try {
            const resp = await this.$store.dispatch({
              type: "PUT_DATA",
              endPoint: "loan",
              reqUrl: "transaction-loan/reject/" + props.row.loanAccountId,
              payload: {
                description: value,
              },
            });
            if (resp.data.code === "SUCCESS") {
              close();
              this.$buefy.toast.open(this.$NotificationUtils.success);
              setTimeout(() => {
                this.property.listElement.loanAccount.currentPage = 1;
                this.property.filterDto.loanAccountNumber = "";
                this.property.filterDto.dataForm = "";
                this.property.filterDto.dateTo = "";
                this.property.filterDto.cifIdName = "";
                this.property.filterDto.referalOfficeId = "";
                this.property.filterDto.officeCode = "";
                this.property.filterDto.cityName = "";
                this.property.filterDto.orderByBalanceEnd = false;
                this.setDefaultOfficeValue();
                this.property.listElement.loanAccount.rows = 0;
                this.getLoanAccount();
              }, 1000);
            } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
              this.$buefy.dialog.alert({
                title: "Harap Tunggu !",
                message: resp.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      resp.data.errorFields
                    )
                  : resp.data.message,
                confirmText: "Ok",
                type: "is-danger",
              });
            } else {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: resp.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      resp.data.errorFields
                    )
                  : resp.data.message,
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          } catch (error) {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: error.response
                ? error.response.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      error.response.data.errorFields
                    )
                  : error.response.statusText
                : "Terjadi Kesalahan",
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        },
      });
    },
    async findLoanAccount() {
      this.table.data.loanAccount = [];
      this.property.listElement.loanAccount.downloading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "transaction-loan/simple-list",
          payload: {
            loanAccountNumber: this.property.filterDto.loanAccountNumber,
            cifIdName: this.property.filterDto.cifIdName,
            dateFrom: this.property.filterDto.dateFrom,
            dateTo: this.property.filterDto.dateTo,
            officeCode: this.property.filterDto.officeCode,
            referalOfficeId: this.property.filterDto.referalOfficeId,
            statusId: this.property.filterDto.statusId,
            cityName: this.property.filterDto.cityName,
            orderByBalanceEnd: this.property.filterDto.orderByBalanceEnd,
            isSubContract: false,
            page: 0,
            size: this.property.listElement.loanAccount.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.loanAccount = resp.data.data.pageResponse.content;
            this.property.listElement.loanAccount.rows =
              resp.data.data.pageResponse.totalElements;
            this.property.listElement.loanAccount.currentPage =
              resp.data.data.pageResponse.pageable.pageNumber + 1;
            this.table.totalRekening = resp.data.data.totalAccount;
            this.table.totalNominatif = resp.data.data.totalNominative;
          } else {
            this.failedGetLoanAccount(resp);
            this.table.totalRekening = 0;
            this.table.totalNominatif = 0;
          }
        }, 500);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetLoanAccount(error);
        }, 500);
      } finally {
        setTimeout(() => {
          this.property.listElement.loanAccount.downloading = false;
        }, 1000);
      }
    },
    async deleteLoanAccount(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      this.$buefy.dialog.confirm({
        message: "Hapus Data Ini ?",
        type: "is-success",
        onConfirm: async () => {
          try {
            const resp = await this.$store.dispatch({
              type: "DELETE_DATA",
              endPoint: "loan",
              reqUrl: "transaction-loan/" + props.row.loanAccountId,
            });
            if (resp.data.code === "SUCCESS") {
              this.$buefy.toast.open(this.$NotificationUtils.success);
              setTimeout(() => {
                this.property.listElement.loanAccount.currentPage = 1;
                this.property.filterDto.loanAccountNumber = "";
                this.property.filterDto.dataForm = "";
                this.property.filterDto.dateTo = "";
                this.property.filterDto.cifIdName = "";
                this.property.filterDto.officeCode = "";
                this.property.filterDto.referalOfficeId = "";
                this.property.filterDto.cityName = "";
                this.property.filterDto.orderByBalanceEnd = false;
                this.setDefaultOfficeValue();
                this.property.listElement.loanAccount.rows = 0;
                this.getLoanAccount();
              }, 1000);
            } else {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: resp.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      resp.data.errorFields
                    )
                  : resp.data.message,
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          } catch (error) {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: error.response
                ? error.response.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      error.response.data.errorFields
                    )
                  : error.response.statusText
                : "Terjadi Kesalahan",
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        },
      });
    },
    routeToPageRincianLoanAccount(props) {
      this.$router.push(
        `/loan/loan-account/${this.encryptBASE64(
          props.row.loanAccountId
        )}?refId=${this.encryptBASE64(
          JSON.stringify({
            loanAccountNumber: props.row.loanAccountNumber,
          })
        )}`
      );
    },
    async getReferenceReferalOfficeCenter() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            params: {
              officeName: "",
              page: 0,
            },
            url: "office",
          }
        );
        if (resp.data.code === "SUCCESS") {
          this.options.office = resp.data.data.content;
          this.options.office.unshift({
            officeId: "",
            officeName: "-- Pilih --",
          });
        }
      } catch (error) {}
    },
    async getReferenceOfficeReferal() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "parameter",
          reqUrl: "office/office-parrent",
          payload: {
            isReferenced: false,
          },
        });
        if (resp.data.code === "SUCCESS") {
          this.options.officeReferal = resp.data.data;
          this.options.officeReferal.unshift({
            officeId: "",
            officeName: "-- Pilih --",
          });
        }
      } catch (error) {}
    },
    async authorizationLoanAccount(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      this.$buefy.dialog.confirm({
        title: "Konfirmasi",
        message: `${
          props.row.isLineFacility === true
            ? "Otorisasi Nomor Kontrak & Setuju Limit sebesar"
            : "Otorisasi Nomor Kontrak & Setuju Droping sebesar"
        } ${
          props.row.loanAkadTypeAcronym ===
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MUDHARABAH ||
          props.row.loanAkadTypeAcronym ===
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MUSYARAKAH
            ? props.row.participateBank
              ? props.row.participateBank
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : 0
            : props.row.loanAkadTypeAcronym ===
                this.fetchAppSession("@vue-session/config-bundle")
                  .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MURABAHAH ||
              props.row.loanAkadTypeAcronym ===
                this.fetchAppSession("@vue-session/config-bundle")
                  .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_IJARAH ||
              props.row.loanAkadTypeAcronym ===
                this.fetchAppSession("@vue-session/config-bundle")
                  .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_IMBT
            ? props.row.nominalPurchase
              ? props.row.nominalPurchase
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : 0
            : props.row.loanAkadTypeAcronym ===
                this.fetchAppSession("@vue-session/config-bundle")
                  .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_QARDH ||
              props.row.loanAkadTypeAcronym ===
                this.fetchAppSession("@vue-session/config-bundle")
                  .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_LINE_FACILITY
            ? props.row.nominalLoan
              ? props.row.nominalLoan
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : 0
            : 0
        } ?`,
        cancelText: "Batal",
        confirmText: "Konfirmasi",
        closeOnConfirm: false,
        type: "is-warning",
        hasIcon: true,
        onConfirm: async (value, { close }) => {
          try {
            const resp = await this.$store.dispatch({
              type: "PUT_DATA_AUTHORIZED",
              endPoint: "loan",
              reqUrl: "transaction-loan/authorized/" + props.row.loanAccountId,
            });
            if (resp.data.code === "SUCCESS") {
              close();
              this.$buefy.toast.open(this.$NotificationUtils.success);
              setTimeout(() => {
                this.property.listElement.loanAccount.currentPage = 1;
                this.property.filterDto.loanAccountNumber = "";
                this.property.filterDto.dataForm = "";
                this.property.filterDto.dateTo = "";
                this.property.filterDto.cifIdName = "";
                this.property.listElement.loanAccount.rows = 0;
                this.property.filterDto.officeCode = "";
                this.property.filterDto.cityName = "";
                this.property.filterDto.referalOfficeId = "";
                this.property.filterDto.orderByBalanceEnd = false;
                this.setDefaultOfficeValue();
                this.getLoanAccount();
              }, 1000);
            } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
              this.$buefy.dialog.alert({
                title: "Harap Tunggu !",
                message: resp.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      resp.data.errorFields
                    )
                  : resp.data.message,
                confirmText: "Ok",
                type: "is-danger",
              });
            } else {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: resp.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      resp.data.errorFields
                    )
                  : resp.data.message,
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          } catch (error) {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: error.response
                ? error.response.data.errorFields
                  ? this.globalNotificationErrorFieldToText(
                      error.response.data.errorFields
                    )
                  : error.response.statusText
                : "Terjadi Kesalahan",
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        },
      });
    },
    handleErrorGetLoanAccount(error) {
      console.log(error.response);
      this.table.data.loanAccount = [];
      this.property.listElement.loanAccount.rows = 0;
      this.property.listElement.loanAccount.currentPage = 1;
      this.property.listElement.loanAccount.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async getLoanAccount() {
      this.table.data.loanAccount = [];
      this.property.listElement.loanAccount.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "transaction-loan/simple-list",
          payload: {
            officeCode: this.property.filterDto.officeCode,
            loanAccountNumber: "",
            cifIdName: "",
            cityName: "",
            referalOfficeId: "",
            statusId: "",
            orderByBalanceEnd: false,
            isSubContract: false,
            page: 0,
            size: 10,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.loanAccount = resp.data.data.pageResponse.content;
            this.property.listElement.loanAccount.rows =
              resp.data.data.pageResponse.totalElements;
            this.table.totalRekening = resp.data.data.totalAccount;
            this.table.totalNominatif = resp.data.data.totalNominative;
          } else {
            this.failedGetLoanAccount(resp);
            this.table.totalRekening = 0;
            this.table.totalNominatif = 0;
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetLoanAccount(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.loanAccount.downloading = false;
        }, timeout);
      }
    },
    helperGetOfficeDataForReference() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_PARAMETER", {
          params: {
            officeName: "",
            page: 0,
          },
          url: "office",
        })
        .then((response) => {
          Promise.all(
            (this.options.cabang = [{ value: "", text: "-- Pilih --" }]),
            response.data.data.content.map((index) => {
              const value = index.officeCode;
              const text = index.officeName;

              this.options.cabang.push({ text, value });
            })
          ).then(() => {});
        });
    },
    failedGetLoanAccount(resp) {
      this.property.listElement.loanAccount.rows = 0;
      this.table.data.loanAccount = [];
      this.property.listElement.loanAccount.message = resp.data.message;
    },
    setDefaultOfficeValue() {
      const getUserDataFromSession = JSON.parse(
        this.decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
      );
      this.property.filterDto.officeCode =
        getUserDataFromSession.officeCode !==
        this.fetchAppSession("@vue-session/config-bundle")
          .VUE_APP_OFFICE_CENTRAL
          ? getUserDataFromSession.officeCode
          : "";
    },
    appendOptionsOrderByBalanceEnd() {
      this.options.orderByBalanceEnd = [
        { text: "Nominal", value: true },
        { text: "Tanggal Buat", value: false },
      ];
    },
    async getReferenceStatusAccount() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_PARAMETER", {
          url: "status",
        });
        console.log(resp);
        if (resp.data.code === "SUCCESS") {
          this.options.statusAccount = [{ value: "", text: "-- Semua --" }];
          resp.data.data.map((index) => {
            const text = index.statusName;
            const value = index.statusId;
            this.options.statusAccount.push({ text, value });
          });
        }
      } catch (error) {}
    },
  },
  mounted() {
    this.setDefaultOfficeValue();
    this.helperGetOfficeDataForReference();
    this.getLoanAccount();
    this.appendOptionsOrderByBalanceEnd();
    this.getReferenceOfficeReferal();
    this.getReferenceReferalOfficeCenter();
    this.getReferenceStatusAccount();
  },
};
